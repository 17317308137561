import styled from '@emotion/styled'

export const LearnInTheMetaverseContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 85vh;
  display: flex;
  justify-content: center;
  color: #ffffff;

  @media (max-width: 1000px) {
    height: 50vh;
  }

  @media (max-width: 800px) {
    height: 40vh;
  }
`

export const LearnInTheMetaverseContents = styled.div`
  position: relative;
  width: 85%;
  display: flex;
  overflow: hidden;
  video {
    min-width: 1600px;
    flex-grow: 1;
    border: none;
    margin: 0;
    padding: 0;
    object-fit: cover;
  }

  @media (max-width: 1200px) {
    video {
      min-width: 1500px;
    }
  }

  @media (max-width: 1000px) {
    video {
      min-width: 1300px;
    }
  }

  @media (max-width: 800px) {
    video {
      min-width: 1000px;
    }
  }

  @media (max-width: 650px) {
    video {
      min-width: 850px;
    }
  }

  @media (max-width: 500px) {
    video {
      min-width: 700px;
    }
  }
`

export const GifOverlay = styled.div`
  position: absolute;
  background-color: rgb(110, 108, 103, 0.3);
  width: 300px;
  bottom: 50px;
  right: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 1000px) {
    bottom: 0px;
    left: 10px;
  }

  @media (max-width: 400px) {
    font-size: 0.75em;
    width: 200px;
  }
`

export const HowItWorksContainer = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
  font-size: 1.65em;

  @media (max-width: 1500px) {
    font-size: 1.3em;
  }

  @media (max-width: 1200px) {
    font-size: 1em;
  }

  @media (max-width: 1000px) {
    font-size: 0.85em;
  }
`

export const HowItWorksContents = styled.div`
  position: relative;
  width: 85%;

  h3 {
    font-weight: normal;
    margin-bottom: 30px;
  }
`

export const CardPairContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 700px) {
    flex-direction: column;
    margin-top: 30px;
  }
`

export const HowItWorksCard = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .card-text-contents {
    width: 50%;
  }

  .card-text-contents p {
    color: #878787;
  }

  a {
    text-decoration: underline;
  }

  @media (max-width: 850px) {
    .card-text-contents {
      width: 80%;
    }
  }

  @media (max-width: 700px) {
    width: 100%;

    .card-text-contents {
      width: 80%;
      margin-top: 10px;
    }

    h3 {
      font-size: 1.5em;
    }

    padding-bottom: 15px;
    border-bottom: 1px solid black;
  }
`

export const MeetOurStudentsContainer = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
`
export const MeetOurStudentsContents = styled.div`
  position: relative;
  width: 85%;

  h3 {
    font-weight: normal;
  }
`
export const StudentCardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`
export const StudentCardContainer = styled.div`
  width: 23%;
  color: #ffffff;
  @media (max-width: 1100px) {
    width: 45%;
    margin-top: 40px;
  }

  @media (max-width: 700px) {
    width: 80%;
  }
`
export const StudentCardContents = styled.div`
  height: 100%;
`
export const StudentCardImgContainer = styled.div`
  position: relative;
  height: 100%;
  color: #000000;
  display: flex;
  img {
    max-height: 100%;
    max-width: 100%;
  }
  .blurredImage {
    opacity: 0.5;
  }
  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    max-height: 100%;
    max-width: 100%;
    border: none;
    margin: 0;
    padding: 0;
  }
  .temporary {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    max-height: 100%;
    max-width: 100%;
    border: none;
    margin: 0;
    padding: 0;
  }
  .closeVideo {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, 0%);
    text-align: center;
    cursor: pointer;
  }

  h3 {
    position: absolute;
    top: 5%;
    left: 50%;
    width: 50%;
    transform: translate(-50%, 0%);
    text-align: center;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      90deg,
      transparent 0%,
      #ffffff 35%,
      transparent 100%
    );
  }

  a {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: -20px;
    text-decoration: underline;
  }
`
export const BenefitsOfFluentWorldsContainer = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 80px;
`
export const BenefitsOfFluentWorldsContents = styled.div`
  position: relative;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    font-weight: normal;
  }
`
export const BenefitsImageContainer = styled.div`
  width: 100%;
  img {
    max-height: 100%;
    max-width: 100%;
  }
`
export const BenefitsFlexBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`
export const BenefitsFlexedDiv = styled.div`
  width: 23%;
  text-align: center;
  p {
    font-size: 0.75em;
    color: #878787;
  }

  @media (max-width: 1100px) {
    width: 45%;
    margin-top: 10px;
  }

  @media (max-width: 700px) {
    width: 80%;
  }
`
